import {actions as toastrActions} from "react-redux-toastr";

export const triggerToaster = (response, backupMessage) => async (dispatch) => {
    if (response.response) {
        //eroare
        const message = response.response.data
            ? response.response.data.message
            : backupMessage;
        dispatch(
            toastrActions.add({
                type: "error",
                title: "Eroare",
                message: message,
                attention: true,
                position: "bottom-right",
                options: {
                    timeOut: 6000,
                    removeOnHover: true,
                    showCloseButton: true,
                },
            })
        );
    } else {
        //success
        const message = response.data.message
            ? response.data.message
            : backupMessage;
        dispatch(
            toastrActions.add({
                type: "success",
                title: "Succes",
                message: message,
                attention: true,
                position: "bottom-right",
                options: {
                    timeOut: 6000,
                    removeOnHover: true,
                    showCloseButton: true,
                },
            })
        );
    }
};

export const triggerToasterError = (message) => async (dispatch) => {
    dispatch(
        toastrActions.add({
            type: "error",
            title: "Eroare",
            message: message,
            attention: true,
            position: "bottom-center",
            options: {
                timeOut: 5000,
                removeOnHover: true,
                showCloseButton: true,
            },
        })
    );
};

export const triggerToasterSuccess = (message, position = "top-center") => async (dispatch) => {
    dispatch(
        toastrActions.add({
            type: "success",
            title: "",
            message: message,
            attention: true,
            position: position,
            options: {
                timeOut: 4000,
                removeOnHover: true,
                showCloseButton: true,
            },
        })
    );
};

export const triggerToasterWarning = (message) => async (dispatch) => {
    dispatch(
        toastrActions.add({
            type: "Warning",
            title: "Eroare",
            message: message,
            attention: true,
            position: "bottom-center",
            options: {
                timeOut: 5000,
                removeOnHover: true,
                showCloseButton: true,
            },
        })
    );
};

export function validateLength(toCheck, min, max) {
    return !!toCheck && (toCheck.length < min || toCheck.length > max);
}

export const reverseTransposed = (data) => {
    const keys = Object.keys(data)
        .filter(key => Array.isArray(data[key]));

    const resultingArray = [];
    const firstKey = keys[0];
    const count = data[firstKey].length;

    for (let i = 0; i < count; i++) {
        const recoveredItem = {};
        keys.forEach(key => {
            recoveredItem[key] = data[key][i];
        })
        resultingArray.push(recoveredItem);
    }
    return resultingArray;
}