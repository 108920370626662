import {createSlice} from "@reduxjs/toolkit";
import {fetchAvize, resetAvize} from "./layers/avizeSlice";

import axios from "axios";
import {fetchApvs} from "./layers/apvSlice";
import {layers} from "./layers/layerSlice";

const initialState = {
    mapLocation: "",
    layerTypes: [],
    legend: "",
    routes: [],
};

function formatFilters(filters) {
    Object.keys(filters).forEach((key) => {
        if (filters[key] instanceof String || typeof filters[key] === "string") {
            if (filters[key] === "") {
                filters[key] = null;
            } else {
                filters[key] = filters[key].split(",");
            }
        }
    });
    return filters;
}

function fieldsToArray(filters) {
    Object.keys(filters).forEach((key) => {
        if (filters[key]) filters[key] = [].concat(filters[key]);
    });
    return filters;
}

function unique(v, i, a) {
    return a.findIndex((t) => t.id === v.id) === i;
}

function parseLegend(fleets = []) {
    let legend = {};
    let colors = fleets.map((fleet) => {
        return {color: fleet.color, fleet: {name: fleet.name, id: fleet.id}};
    });
    let issiTypes = fleets
        .map((fleet) => fleet.issis.map((issi) => issi.issiType))
        .flat()
        .filter(unique);

    legend.colors = colors;
    legend.issiTypes = issiTypes;
    return legend;
}

const mapSlice = createSlice({
    name: "map",
    initialState,
    reducers: {
        search: (state, action) => {
            state.filters = fieldsToArray(formatFilters(state.temporary));
        },
        resetRoutes: (state, action) => {
            state.routes = [];
        },
        setMapLocation: (state, action) => {
            state.mapLocation = action.payload;
        },
        setLayerTypes: (state, action) => {
            state.layerTypes = action.payload;
        },
        setPolygon: (state, action) => {
            state.UATs = action.payload.map((entry) => ({
                ...entry,
                polygon: entry.polygon.map((point) => [point.x, point.y]),
            }));
        },
        set: (state, action) => {
            state = {...state, ...action.data};
        },
        resetFilters: (state, action) => {
            Object.keys(state.filters).forEach((filter) => {
                state.filters[filter].value = initialState.filters[filter].value;
            });
        },
    },
});

export const {setMapLocation, setLayerTypes} = mapSlice.actions;
export default mapSlice.reducer;

// export const onResetFilters = () => async (dispatch) => {
//   dispatch(resetFilters());
//   dispatch(fetchPatients());
// };
// export const onSearch = () => async (dispatch, state) => {
//   dispatch(search());
//   // dispatch(resetRoutes())
//   // dispatch(fetchLocations(state.map.temporary))
//   // dispatch(fetchFleetsInfo())
// };
const fetchMapLocation = () => async (dispatch) => {
    axios.get("api/map/location").then((res) => {
        dispatch(setMapLocation(res.data));
    });
};

const fetchLayerTypes = () => async (dispatch) => {
    axios.get("api/layer/types").then((res) => {
        dispatch(setLayerTypes(res.data));
    });
};

export const onFirstView = () => async (dispatch) => {
    dispatch(fetchMapLocation()); //requesting gis server url
    dispatch(fetchLayerTypes());
};

export const fetchLayerInfo = (type) => async (dispatch, getState) => {
    const layerKeys = Object.keys(layers);
    const layerKey = layerKeys[type.value - 1];
    const layer = layers[layerKey]
    const {filters} = getState().layers.layer[layer.name];
    type.value === 1 && dispatch(fetchAvize(filters));
    type.value === 4 && dispatch(fetchApvs(filters));
};

export const resetLayerInfo = (type) => async (dispatch) => {
    dispatch(resetAvize());
};
