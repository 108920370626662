import {createSlice} from "@reduxjs/toolkit";
import Axios from "axios";
import {toggleLoading} from "../layoutSlice";
import {reverseTransposed, triggerToasterSuccess} from "../../utils/shared";

const initialState = {
    apvs: null,
};
const apvSlice = createSlice({
    name: "apv",
    initialState,
    reducers: {
        setLocations(state, action) {
            state.apvs = reverseTransposed(action.payload);
        },
        setApvs(state, action) {
            state.menuIsOpen = !state.menuIsOpen;
        },
        resetApvs: (state) => initialState,
    },
});

export const {setLocations, resetApvs} = apvSlice.actions;
export default apvSlice.reducer;

export const fetchApvs = (filters = null, callback = () => {
}, withMessage = true) => async (dispatch) => {
    dispatch(toggleLoading())

    Axios.get(
        "api/apv/web/locations",
        filters
            ? {
                params: {
                    ...filters,
                },
            }
            : {}
    )
        .then((res) => {
            dispatch(setLocations(res.data));
            const key = Object.keys(res.data)[0];
            let nrApv = res.data[key].length;
            dispatch(
                triggerToasterSuccess(
                    `${nrApv === 1 ? "A" : "Au"} fost gasit${nrApv === 1 ? "" : "e"} ${nrApv} APV${
                        nrApv === 1 ? "" : "-uri"
                    }`
                    , "top-center")
            );

        })
        .catch((err) => {

        }).finally(() => {
        dispatch(toggleLoading())
    });
};

export const fetchCompaniiOcol = () => {
    Axios.get(
        "api/suggestions/ocol"
    ).then(res => {

    })
}