import {Button, Grid, Image, Modal} from "semantic-ui-react";
import CustomExpansionPanel from "../../../CustomExpansionPanel/CustomExpansionPanel";
import ThroughLineHeader from "../../../ThroughLineHeader/throughLineHeader";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import React, {useState} from "react";
import {fetchRoute} from "../../../../reducers/routeSlice";
import {informatiiEntitatiImplicate} from "./InfoRendering";
import {useDispatch} from 'react-redux'
import {PropTypes} from "prop-types";
import moment from 'moment'

moment.locale('ro')

const InformatiiAviz = (props) => {

    const {onClose, onOpen, open} = props;
    const [aviz, setAviz] = useState(props.aviz);
    debugger
    const dispatch = useDispatch();

    return <Modal
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        closeIcon
    >
        {/*<Modal.Header>Informatii Aviz</Modal.Header>*/}
        <Modal.Content>
            {!!aviz && (
                <>
                    <CustomExpansionPanel
                        open
                        header={() => (
                            <ThroughLineHeader
                                style={{margin: 0, paddingBottom: "0.2em"}}
                                render={"h4"}
                            >
                                {"Informa\u021Bii Aviz de Transport"}
                            </ThroughLineHeader>
                        )}
                        body={() => (
                            <Grid stackable>
                                <Grid.Row columns={2} stretched>
                                    <Grid.Column>
                                        <p>
                                            <strong>Tip Transport:</strong> {aviz.numeTipAviz}
                                        </p>
                                        <p>
                                            <strong>Cod Aviz:</strong> {aviz.codAviz}
                                        </p>
                                        <p>
                                            <strong>Nr. Identificare Mijloc Transport:</strong>{" "}
                                            {aviz.nrIdentificare}
                                        </p>
                                        {!!aviz.remorca && <p>
                                            <strong>{'Nr. Identificare Remorc\u0103:'}</strong>{" "}
                                            {aviz.remorca}
                                        </p>}
                                        {!!aviz.identificatorContainer && <p>
                                            <strong>{'Identificator container:'}</strong>{" "}
                                            {aviz.identificatorContainer}
                                        </p>}
                                    </Grid.Column>
                                    <Grid.Column>
                                        <p>
                                            <strong>Provenienta:</strong>{" "}
                                            {aviz.provenienta}
                                        </p>

                                        <p>
                                            <strong>Data Emiterii:&nbsp;</strong>
                                            {/*{new Date(*/}
                                            {/*    aviz.valabilitate.emitere*/}
                                            {/*).toLocaleString()}*/}
                                            {moment(aviz.valabilitate.emitere).format("DD/MM/YYYY HH:mm:ss")}
                                        </p>
                                        {!!aviz.valabilitate.emitere && !!aviz.valabilitate.finalizare && <p>
                                            <strong>Valabilitate:&nbsp;</strong>
                                            {moment(aviz.valabilitate.emitere).format("DD/MM/YYYY HH:mm:ss")}{" "}
                                            -{" "}
                                            {moment(aviz.valabilitate.finalizare).format("DD/MM/YYYY HH:mm:ss")}
                                        </p>}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        )}
                    />
                    {informatiiEntitatiImplicate(aviz)}
                    {!!aviz.marfa && <CustomExpansionPanel
                        header={() => (
                            <ThroughLineHeader
                                style={{margin: 0, paddingBottom: "0.2em"}}
                                render={"h4"}
                            >
                                Volum
                            </ThroughLineHeader>
                        )}
                        body={() => (
                            <>
                                {!!aviz.marfa &&
                                <TableContainer container={Paper}>
                                    <Table aria-label="collapsible table" size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left"/>
                                                <TableCell align="left"/>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell variant={'head'}>Grupe Specii</TableCell>
                                                <TableCell>{aviz.marfa.grupeSpecii}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell variant={'head'}>Specii</TableCell>
                                                <TableCell>{aviz.marfa.specii}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell variant={'head'}>Sortimente</TableCell>
                                                <TableCell>{aviz.marfa.sortimente}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell variant={'head'} align="left">Total (mc)</TableCell>
                                                <TableCell align="left">
                                                    {Number(aviz.marfa.total).toFixed(6)}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                }
                            </>
                        )}
                    />}
                    {!!aviz.poze && aviz.poze.length > 0 && (
                        <CustomExpansionPanel
                            header={() => (
                                <ThroughLineHeader
                                    style={{margin: 0, paddingBottom: "0.2em"}}
                                    render={"h4"}
                                >
                                    Poze Transport
                                </ThroughLineHeader>
                            )}
                            body={() => (
                                <Grid>
                                    <Grid.Row columns={3}>
                                        {aviz.poze.map((bytes, i) => (
                                            <Grid.Column key={i} fluid>
                                                <Image
                                                    src={"data:image/jpg;base64," + bytes}
                                                    size="huge"
                                                />
                                            </Grid.Column>
                                        ))}
                                    </Grid.Row>
                                </Grid>
                            )}
                        />
                    )}
                    {
                        !!aviz.hasFinishedTransport &&
                        <Button style={{paddingRight: "7%"}}
                                fluid
                                onClick={() => {
                                    dispatch(fetchRoute(aviz))
                                    onClose()
                                }}>
                            {"Vizualizeaz\u0103 Traseu"}
                        </Button>
                    }

                </>
            )}
        </Modal.Content>
    </Modal>
}

InformatiiAviz.propTypes = {
    open: PropTypes.bool.isRequired,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    aviz: PropTypes.object.isRequired
}
export default InformatiiAviz;