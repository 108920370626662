import React, {useEffect, useState} from 'react'
import CustomExpansionPanel from "../../CustomExpansionPanel/CustomExpansionPanel";
import ThroughLineHeader from "../../ThroughLineHeader/throughLineHeader";
import {Button as SemanticButton, Dropdown, Grid, Icon, Input} from "semantic-ui-react";
import {useDispatch, useSelector} from "react-redux";
import {layers, setFilters} from "../../../reducers/layers/layerSlice";
import {fetchApvs} from "../../../reducers/layers/apvSlice";
import {fetchCompanii, fetchStareApvSuggestions} from "../../../reducers/suggestionsSlice";
import {YearInput} from "semantic-ui-calendar-react";

import L from 'leaflet'
import moment from "moment";
import {validateLength} from "../../../utils/shared";

const LAYER = layers.APV_NR;
export const ApvFilters = () => {
    const regExp = new RegExp("^[a-zA-Z0-9]*$");
    const {filters} = useSelector(state => state.layers.layer[LAYER.name])
    const {companii, stareApv, naturaProdus, felTaiere} = useSelector(state => state.suggestions)
    const changeFilter = (value) => {
        dispatch(setFilters(LAYER, value));
    }

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCompanii());
        // dispatch(fetchNaturaProdusSuggestions());
        // dispatch(fetchFelTaiereSuggestions());
        dispatch(fetchStareApvSuggestions());
        dispatch(fetchApvs(filters));
        const elem = L.DomUtil.get('apv-filters');
        L.DomEvent.on(elem, 'mousewheel', L.DomEvent.stopPropagation)
    }, [])

    const [errors, setErrors] = useState({numar: false})

    const handleSearch = () => {
        if(validateLength(filters.numarApv, 5,15)) {
            setErrors({...errors, numar: true})
            return;
        }
        dispatch(fetchApvs(filters));
    }

    return <CustomExpansionPanel
        header={() => <ThroughLineHeader
            style={{margin: 0}}
            render={"h4"}
        >
            Cautare
        </ThroughLineHeader>}
        body={() =>
            <Grid id="apv-filters" style={{padding: '1rem', maxWidth: "400px"}} columns={"equal"}>
                <Grid.Row>
                    <Grid.Column>
                        <Dropdown
                            placeholder={'Ocol'}
                            fluid
                            // multiple
                            search
                            clearable
                            selection
                            options={companii}
                            value={filters.companie}
                            onChange={(event, {value}) => {
                                changeFilter({companie: value})
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
                {/*<Grid.Row>*/}
                {/*    <Grid.Column>*/}
                {/*        <Dropdown*/}
                {/*            placeholder={'Natur\u0103 Produs'}*/}
                {/*            fluid*/}
                {/*            // multiple*/}
                {/*            search*/}
                {/*            clearable*/}

                {/*            selection*/}
                {/*            options={naturaProdus}*/}
                {/*            value={filters.naturaProdus}*/}
                {/*            onChange={(event, {value}) => {*/}
                {/*                changeFilter({naturaProdus: value})*/}
                {/*            }}*/}
                {/*        />*/}
                {/*    </Grid.Column>*/}
                {/*</Grid.Row>*/}
                {/*<Grid.Row>*/}
                {/*    <Grid.Column>*/}
                {/*        <Dropdown*/}
                {/*            placeholder={'Fel T\u0103iere'}*/}
                {/*            fluid*/}
                {/*            // multiple*/}
                {/*            search*/}
                {/*            clearable*/}

                {/*            selection*/}
                {/*            options={felTaiere}*/}
                {/*            value={filters.felTaiere}*/}
                {/*            onChange={(event, {value}) => {*/}
                {/*                changeFilter({felTaiere: value})*/}
                {/*            }}*/}
                {/*        />*/}
                {/*    </Grid.Column>*/}
                {/*</Grid.Row>*/}
                <Grid.Row>
                    <Grid.Column>
                        <Dropdown
                            placeholder={'Stare APV'}
                            fluid
                            // multiple
                            search
                            clearable

                            selection
                            options={stareApv}
                            value={filters.stareApv}
                            onChange={(event, {value}) => {
                                changeFilter({tip: value})
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
                {
                    //istoric si reprimit
                    [0, 6].includes(filters.stareApv) ? <Grid.Row divided columns={2}>
                            <Grid.Column>
                                <YearInput
                                    fluid
                                    name="apv-de-la"
                                    disableMinute
                                    popupPosition={"top center"}
                                    placeholder="De la"
                                    value={filters.deLa}
                                    animation={false}
                                    maxDate={moment().year()}
                                    onChange={(event, {value}) => {
                                        changeFilter({deLa: value});
                                    }}
                                    hideMobileKeyboard
                                    className={"datepicker"}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <YearInput
                                    fluid
                                    name="apv-pana-la"
                                    disableMinute
                                    popupPosition={"top center"}
                                    placeholder={"Pana la"}
                                    value={filters.panaLa}
                                    animation={false}
                                    maxDate={moment().year()}
                                    onChange={(event, {value}) => {
                                        changeFilter({panaLa: value});
                                    }}
                                    hideMobileKeyboard
                                    className={"datepicker"}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        : null
                }

                <Grid.Row>
                    <Grid.Column>
                        <Input
                            error={validateLength(filters.numarApv,5,15)}
                            // label={{ tag: true, content: "Nr. auto" }}
                            labelPosition="right"
                            name={"numar"}
                            placeholder={"Num\u0103r APV"}
                            iconPosition="right"
                            icon="barcode"
                            value={filters.numarApv}
                            fluid
                            onChange={(event, {value}) => {
                                if (errors.numar) {
                                    setErrors({numar: false});
                                }
                                if(!regExp.test(value.trim()))
                                    return;
                                changeFilter({numarApv: value.trim()});
                            }}
                            style={{minWidth: '200px'}}
                        />
                        {!!errors.numar && <label style={{position: "absolute", color:"red", opacity:'0.6'}}>{"Min. 5 caractere"}</label>}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <SemanticButton
                        fluid
                        icon
                        color={"teal"}
                        onClick={handleSearch}
                    >
                        <Icon name={"search"}/>
                        {"C\u0103utare"}
                    </SemanticButton>
                </Grid.Row>
            </Grid>
        }
        open={false}
    />
}