import {createSlice} from "@reduxjs/toolkit";
import Axios from "axios";
import {reverseTransposed, triggerToasterSuccess} from "../../utils/shared";
import moment from "moment";
import _ from 'lodash'
import {toggleLoading} from "../layoutSlice";

const initialState = {
    avize: null,
};


const avizeSlice = createSlice({
    name: "avize",
    initialState,
    reducers: {
        setLocations(state, action) {
            const normalized = reverseTransposed(action.payload);
            state.avize = _.groupBy(normalized.map(e => ({
                ...e,
                tipAviz: e.tipAviz > 7 ? 99 : e.tipAviz
            })), "idDepozit");
        },
        setAvize(state, action) {
            state.menuIsOpen = !state.menuIsOpen;
        },
        resetAvize: (state) => initialState,
    },
});

export const {setLocations, resetAvize} = avizeSlice.actions;
export default avizeSlice.reducer;

export const fetchAvize = (filters = null, callback = () => {
}, withMessage = true) => async (dispatch) => {
    // const split = filters.date.split("/")
    // const date = moment(new Date(split[2], split[1] - 1, split[0])).format("YYYY-MM-DDTHH:mm:ss");
    dispatch(toggleLoading());
    Axios.get(
        "api/aviz/locations",
        filters
            ? {
                params: {
                    ...filters,
                },
            }
            : {}
    )
        .then((res) => {
            dispatch(setLocations(res.data));
            const key = Object.keys(res.data)[0];
            let nrAvize = res.data[key].length;
            // callback(filters, res.data);
            withMessage && dispatch(triggerToasterSuccess(
                `In intervalul ${moment().add(-4, 'd').format("DD/MM/yyyy")} - ${moment().format("DD/MM/yyyy")} ${
                    nrAvize === 1 ? "a" : "au"
                } fost gasit${nrAvize === 1 ? "" : "e"} ${nrAvize} aviz${
                    nrAvize === 1 ? "" : "e"
                }`
                , "top-center")
            );
        })
        .catch((err) => {

        }).finally(() => {
        dispatch(toggleLoading());
    });
};