import {divIcon} from "leaflet";
import ReactDOMServer from "react-dom/server";
import CustomIcon from "./CustomIcon";
import React from "react";
import LocationIcon from "@material-ui/icons/PersonPinCircle";

const styles = (theme) => ({
    myIcon: {
        background: `url(${LocationIcon})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        width: "25px",
        height: "25px",
    },
    redIcon: {
        color: "whiteblue",
    },
});
export const makeIcon = (iconName, color) => {
    return divIcon({
        className: styles.redIcon,
        html: ReactDOMServer.renderToString(
            <CustomIcon
                name={iconName}
                style={{
                    width: "1.7rem",
                    height: "1.7em",
                }}
            />
        ),
        iconSize: [30, 30],
    });
};