import React, {useEffect, useState} from 'react'
import CustomExpansionPanel from "../../CustomExpansionPanel/CustomExpansionPanel";
import ThroughLineHeader from "../../ThroughLineHeader/throughLineHeader";
import {Button as SemanticButton, Grid, Icon, Input, Label} from "semantic-ui-react";
import Control from "react-leaflet-control";
import {useDispatch, useSelector} from "react-redux";
import {layers, resetFilters, setFilters} from "../../../reducers/layers/layerSlice";
import {fetchApvs} from "../../../reducers/layers/apvSlice";


const LAYER = layers.APV;
export const ApvCodFilters = () => {

    const {filters} = useSelector(state => state.layers.layer[LAYER.name])

    const changeFilter = (value) => {
        dispatch(setFilters(LAYER, value));
    }

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(resetFilters());
        }
    }, [])

    const [errors, setErrors] = useState({numar: false})

    const handleSearch = () => {
        if (!filters.numar) {
            setErrors({numar: true});
            return;
        }
        dispatch(fetchApvs(filters));
    }

    return <Control position={"bottomright"}>
        <CustomExpansionPanel
            header={() => <ThroughLineHeader
                style={{margin: 0}}
                render={"h4"}
            >
                Cautare
            </ThroughLineHeader>}
            body={() =>

                <Grid style={{padding: '1rem'}}>
                    <Grid.Row>
                        <Grid.Column>
                            <Input
                                error={errors.numar}
                                // label={{ tag: true, content: "Nr. auto" }}
                                labelPosition="right"
                                name={"numar"}
                                placeholder={"Num\u0103r APV"}
                                iconPosition="right"
                                icon="barcode"
                                value={filters.numarApv}
                                fluid
                                onChange={(event, {value}) => {
                                    if (errors.numar) {
                                        setErrors({numar:false});
                                    }
                                    changeFilter({numarApv: value});
                                }}
                                style={{minWidth: '200px'}}
                            />

                            {errors.cod && <Label color={"red"} pointing={"above"} basic style={{width:'100%'}}>
                                {"Introduce\u021Bi un num\u0103r de APV"}
                            </Label>}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <SemanticButton
                            fluid
                            icon
                            color={"teal"}
                            onClick={handleSearch}
                        >
                            <Icon name={"search"}/>
                            {"C\u0103utare"}
                        </SemanticButton>
                    </Grid.Row>
                </Grid>
            }
            open={false}
        />
    </Control>
}