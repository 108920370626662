import {createSlice} from '@reduxjs/toolkit'

// export default function (state=false, action){
//     switch(action.type){
//         case RAISE_APP_ERROR:
//             return true;
//         default:
//             return state;
//     }
// }


const appHasErrorSlice = createSlice({
    name:'appHasError',
    initialState:false,
    reducers: {
        raiseAppError : (state,action) => {
                state = true;
        }
    }
})

export const {raiseAppError} = appHasErrorSlice.actions;

export default appHasErrorSlice.reducers;

