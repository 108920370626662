import {createSlice} from "@reduxjs/toolkit";

export const layers = {
    AVIZE: {name: "avize"},
    AVIZ_NR: {name: "avizNr"},
    AVIZ_COD: {name: "avizCod"},
    APV: {name: "apvs"},
    APV_NR: {name: "apvNr"}
}
const initialState = {
    //vizualizare avize pe harta, pe zi si in interval de ore
    [layers.AVIZE.name]: {
        filters: {
            nr: "",
            cod: "",
            nrApv: "",
            // date: new Date().getTime(),
            // start: null,
            // end: null,
            tip: 0,
        }
    },
    //cautare aviz dupa cod aviz
    [layers.AVIZ_COD.name]: {
        filters: {
            cod: ""
        }
    },
    //cautare aviz dupa nr. auto / nr. apv
    [layers.AVIZ_NR.name]: {
        filters: {
            nr: "", //nr identificare mijloc transport
            nrApv: "", //nr APV
            start: null,
            end: null
        }
    },
    [layers.APV_NR.name]: {
        filters: {
            numar: ""
        }
    },
    [layers.APV.name]: {
        filters: {
            companie: "",
            numarApv:""
        },
    }
};
const layerSlice = createSlice({
    name: "layer",
    initialState,
    reducers: {
        setFilter(state, action) {
            const {layer, value} = action.payload;
            state[layer].filters = {...state[layer].filters, ...value};
        },
        resetFilters: (state, action) => initialState,
    },
});

export const {setFilter, getLayerFilters, resetFilters} = layerSlice.actions;
export default layerSlice.reducer;
export const setFilters = (layer, value) => async (dispatch) => {
    dispatch(setFilter({layer: layer.name, value}));
}
