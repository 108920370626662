import React, {useEffect, useState} from "react";
import {resetAvize} from "../../../reducers/layers/avizeSlice";
import {resetRoutes} from "../../../reducers/routeSlice";
import {useDispatch, useSelector} from "react-redux";
import {Marker} from "react-leaflet";
import Axios from "axios";
import InformatiiApv from "../modal/apv/InformatiiApv";
import {toggleLoading} from "../../../reducers/layoutSlice";
import {makeIcon} from "../../../utils/icons";
import L from 'leaflet'
import {triggerToasterError} from "../../../utils/shared";
import {Button, Segment} from "semantic-ui-react";
import * as ReactDOM from "react-dom";
import '../map.css'

const MarkerApvPopup = ({apv, openAPVInfoModal, open, stareApv}) => {
    const [grupeSpecii, setGrupeSpecii] = useState([]);
    const [isLoading, setIsLoading] = useState(true)

    function requestGrupeSpecii() {
        Axios.get(`api/apv/${apv.nr}/grupe-specii`).then(res => {
            setGrupeSpecii(res.data);
        }).catch(err => {
            triggerToasterError("Nu au putut fi aduse grupele de specii pentru APV-ul ales");
        })
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        requestGrupeSpecii();
    }, [])
    // return <Popup onOpen={() => {
    //     if (grupeSpecii.length === 0)
    //         requestGrupeSpecii()
    // }} className={mapStyles.popup}>
    return <Segment basic>
        <p>Numar APV: {apv.nr}</p>
        <p>Stare: {stareApv.filter(s => s.value === apv.codStare)[0].text}</p>
        <p>Fire inventariate: {apv.fire}</p>
        <p>{apv.emiteAvize ? "Se" : "Nu se"} pot emite avize din acest APV</p>
        <Segment basic loading={isLoading}>
            <p>
                Grupe de
                specii: {grupeSpecii.length > 0 ? grupeSpecii.join(", ") : "-"}
            </p>
        </Segment>
        {/*Nu se afiseaza daca e AUTORIZAT*/}
        {apv.codStare !== 3 && <Button fluid onClick={() => openAPVInfoModal(apv)}>
            {"Informa\u021bii APV"}
        </Button>}
    </Segment>
    // </Popup>
}

const makeIconCache = {}
const getIcon = (cod) => {
    if (!makeIconCache[cod]) {
        makeIconCache[cod] = makeIcon(cod)
    }
    return makeIconCache[cod]
}
const MarkerApv = ({apv, openAPVInfoModal, stareApv}) => {
    const [popupOpen, setPopupOpen] = useState(false);
    return <Marker
        key={apv.nr}
        position={[apv.lat, apv.lng]}
        icon={getIcon(apv.codStare)}
        onClick={() => {
            setPopupOpen(true)
        }}
    >
        <MarkerApvPopup apv={apv} openAPVInfoModal={openAPVInfoModal} open={popupOpen} stareApv={stareApv}/>
    </Marker>
}


const ApvMarkers = ({mapRef}) => {
    const dispatch = useDispatch();

    const {apvs} = useSelector((state) => state.layers.apvs);
    const [apvInfoModalOpen, setApvInfoModalOpen] = useState(false);
    const [apv, setApv] = useState(null);
    const [cluster, setCluster] = useState(null);
    const [apvMarkers, setApvMarkers] = useState(null)

    const openAPVInfoModal = (apv) => {
        dispatch(toggleLoading());
        dispatch(resetRoutes())
        Axios
            .get(`api/apv/${apv.nr}`)
            .then((res) => {
                setApv({...res.data, ...apv});
                // dispatch(toggleLoading());
                setApvInfoModalOpen(true);
            })
            .catch((err) => {

            })
            .finally(() => dispatch(toggleLoading()));
    }

    const {stareApv} = useSelector((state) => state.suggestions)

    useEffect(() => {
        dispatch(resetAvize())
        dispatch(resetRoutes())
        if (!!apvs && apvs.length > 0) {
            if (cluster != null)
                mapRef.current.leafletElement.removeLayer(cluster)
            let toBeCluster = L.markerClusterGroup();
            let markers = apvs.map(apv => {
                let m = L.marker({lat: apv.lat, lng: apv.lng}, {
                    icon: getIcon(apv.codStare)
                })
                m.bindPopup(`<div class='apv-popup' id='apv-popup-${apv.nr}'>Loading</div>`)
                    .on('popupopen', () => {
                        ReactDOM.hydrate(<MarkerApvPopup apv={apv} openAPVInfoModal={openAPVInfoModal}
                                                         open={true}
                                                         stareApv={stareApv}/>, document.getElementById(`apv-popup-${apv.nr}`))
                    })
                return m;
            })
            markers.forEach(m => {
                toBeCluster.addLayer(m)
            });
            mapRef.current.leafletElement.addLayer(toBeCluster);
            setCluster(toBeCluster);
        } else {
            if (cluster != null)
                mapRef.current.leafletElement.removeLayer(cluster)
            setCluster(null);
        }
    }, [apvs])

    return [apvMarkers,
        <InformatiiApv
            onClose={() => setApvInfoModalOpen(false)}
            onOpen={() => setApvInfoModalOpen(true)}
            open={apvInfoModalOpen}
            apv={apv}
        />];

}


export default ApvMarkers;