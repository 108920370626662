import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {withStyles} from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";

const style = {
    content: {
        display: "block",
    },
};

const CustomExpansionPanel = (props) => {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (props.open) setOpen(props.open);
    }, []);

    const handleChange = (event) => {
        if (props.onOpenChange !== undefined) {
            props.onOpenChange(!open);
        }
        if (open === false) {
            //daca se deschide, sa aduca datele pentru filtre
            if (props.onOpenFurtherAction !== undefined) {
                props.onOpenFurtherAction();
            }
        }
        setOpen(!open);
    };

    const classes = props.classes;
    return (
        <div>
            {(props.visible === undefined || props.visible === true) && (
                <ExpansionPanel
                    disabled={props.disabled !== undefined && props.disabled === true}
                    onChange={handleChange}
                    expanded={open}
                >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon/>}
                        classes={classes}
                    >
                        {props.header()}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                        style={{display: "flex", flexDirection: "column"}}
                    >
                        {props.body()}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            )}
        </div>
    );
};

CustomExpansionPanel.propTypes = {
    header: PropTypes.func.isRequired,
    body: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    visible: PropTypes.bool,
    open: PropTypes.bool,
    onOpenChange: PropTypes.func,
};

export default withStyles(style)(CustomExpansionPanel);
