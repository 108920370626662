import React, {useEffect, useState} from "react";
import {resetApvs} from "../../../reducers/layers/apvSlice";
import {resetRoutes} from "../../../reducers/routeSlice";
import {makeIcon} from "../../../utils/icons";
import mapStyles from "../map.module.css";
import {Button, Grid, Header, Icon, Segment} from "semantic-ui-react";
import {useDispatch, useSelector} from "react-redux";
import InformatiiAvizModal from "../modal/aviz/InformatiiAviz";
import {toggleLoading} from "../../../reducers/layoutSlice";
import Axios from "axios";
import L from 'leaflet'
import * as ReactDOM from "react-dom";

const SingleAvizMarker = ({aviz, openAvizInfoModal}) => {
    return <Segment basic><Header as={'h3'}>
        Avize Electronice
    </Header>
        <Button basic color={"facebook"} icon labelPosition={"left"}
                onClick={() => openAvizInfoModal(aviz.codAviz)}>
            <Icon color={"blue"} name={'file alternate outline'}/>
            {aviz.codAviz}
        </Button>
    </Segment>;
}

const MultiAvizMarker = ({avize, openAvizInfoModal}) => {

    const content = avize.map((avizDepozit) => {
        return <Grid.Row style={{padding: '0.3rem'}}>
            <Button basic color={"facebook"} icon labelPosition={"left"}
                    onClick={() => openAvizInfoModal(avizDepozit.codAviz)}>
                <Icon color={"blue"} name={'file alternate outline'}/>
                {avizDepozit.codAviz}
            </Button>
        </Grid.Row>
    });

    return <Segment basic className={[mapStyles.popup, "scrolling content"].join(" ")}><Header as={'h3'}>
        Avize Electronice
    </Header>
        <Grid className={"scrolling content"}
              style={{overflowY: 'auto', maxHeight: '300px'}}>
            {content}
        </Grid>
    </Segment>

}


const AvizMarkers = ({mapRef}) => {
    const dispatch = useDispatch();

    const [cluster, setCluster] = useState(null);
    const {avize} = useSelector((state) => state.layers.avize);
    const [avizInfoModalOpen, setAvizInfoModalOpen] = useState(false);

    const [avizeMarkers, setAvizeMarkers] = useState(null)
    const [aviz, setAviz] = useState(null);

    const openAvizInfoModal = (codAviz) => {
        dispatch(toggleLoading());
        dispatch(resetRoutes())
        Axios.get(`api/aviz/${codAviz}`)
            .then((res) => {
                setAviz({...res.data});
                setAvizInfoModalOpen(true);
            })
            .catch((err) => {

            })
            .finally(() => dispatch(toggleLoading()));
    };

    useEffect(() => {
        dispatch(resetApvs());
        dispatch(resetRoutes())
        if (!!avize && Object.keys(avize).length > 0) {
            debugger;
            if (cluster != null)
                mapRef.current.leafletElement.removeLayer(cluster);

            const toBeCluster = L.markerClusterGroup();

            Object.keys(avize).forEach((key) => {
                //avizele vin grupate pe id depozit

                if (Number(key) !== -1) {
                    //pe -1 avizele nu au depozit provenienta,
                    // asa ce se vor folosi coordonatele de emitere a avizelor
                    const aviz = avize[key][0]
                    let coords = {lat: avize[key][0].lat, lng: avize[key][0].lng};
                    let tooltip = null;

                    if (avize[key].length === 1) {
                        tooltip = <SingleAvizMarker aviz={avize[key][0]} openAvizInfoModal={openAvizInfoModal}/>
                    } else {
                        tooltip = <MultiAvizMarker avize={avize[key]} openAvizInfoModal={openAvizInfoModal}/>
                    }
                    let m = L.marker(coords, {
                        icon: makeIcon(aviz.tipAviz, aviz.markerColor)
                    })
                    m.bindPopup(`<div class='aviz-popup' id='aviz-popup-${aviz.codAviz}'>Loading</div>`)
                        .on('popupopen', () => {
                            ReactDOM.hydrate(tooltip, document.getElementById(`aviz-popup-${aviz.codAviz}`))
                        })
                    toBeCluster.addLayer(m);
                } else {
                    avize[key].forEach((av) => {
                        let m = L.marker({lat: av.lat, lng: av.lng}, {
                            icon: makeIcon(av.tipAviz, av.markerColor)
                        })
                        m.bindPopup(`<div class='aviz-popup' id='aviz-popup-${av.codAviz}'>Loading</div>`)
                            .on('popupopen', () => {
                                ReactDOM.hydrate(<SingleAvizMarker aviz={av}
                                                                   openAvizInfoModal={openAvizInfoModal}/>, document.getElementById(`aviz-popup-${av.codAviz}`))
                            })
                        toBeCluster.addLayer(m);
                    });
                }
            });
            mapRef.current.leafletElement.addLayer(toBeCluster);
            setCluster(toBeCluster);
        } else {
            if (cluster != null)
                mapRef.current.leafletElement.removeLayer(cluster);
            // setCluster(null);
        }
    }, [avize])

    return !!aviz && !!avizInfoModalOpen ? <InformatiiAvizModal
        onClose={() => setAvizInfoModalOpen(false)}
        onOpen={() => setAvizInfoModalOpen(true)}
        open={avizInfoModalOpen}
        aviz={aviz}
    /> : ""
}

export default AvizMarkers;