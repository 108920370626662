import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {Route, Switch, withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import ConnectedMap from "../Map/Map";
import stslogo from "../../assets/logo-200.png";
import {PuffLoader} from "react-spinners";
import {Container, Header, Image, Segment} from "semantic-ui-react";
import mmaplogo from "../../assets/sigla_mmap.png";
import './layout.css'

const Layout = (props) => {
    const loading = useSelector((state) => state.layout.loading);

    useEffect(() => {
    }, []);

    return (
        <Container fluid>
            <Segment.Group horizontal>
                <Segment basic style={{backgroundColor: "#00887A", margin: 0, color: 'white'}} floated={"left"}>
                    <Header icon style={{color: 'white', margin: 0,}}>
                        <Image circular
                               src={mmaplogo}
                               alt="Ministerul Mediului Apelor si Padurilor Logo"
                               href={"http://www.mmediu.ro"}
                               target={"_blank"}
                        />
                    </Header>

                </Segment>
                <Segment raised textAlign={"center"} fluid basic
                         style={{backgroundColor: "#00887A", margin: 0, color: 'white'}}>

                    <Header icon as={"h1"}
                            style={{color: 'white', margin: 0, fontFamily: 'none!important', textAlign: "center"}}
                            className={"title"}
                            size={"medium"}>
                        {"SUMAL 2.0 - Inspectorul P\u0103durii"}
                    </Header>
                </Segment>
                <Segment basic style={{backgroundColor: "#00887A", margin: 0, color: 'white'}} floated={"right"}>
                    <Header icon style={{color: 'white', margin: 0, float: 'right'}} size={"medium"}>
                        <Image circular
                               src={stslogo}
                               alt="Serviciul De Telecomunicatii Speciale Logo"
                               href={"https://www.sts.ro"}
                               target={"_blank"}
                        />
                    </Header>
                </Segment>
            </Segment.Group>

            <Segment raised style={{margin: 0, padding: '.3rem', position: 'relative'}}>
                <Switch>
                    {<Route path="/" component={ConnectedMap}/>}
                </Switch>
                {loading && (
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            zIndex: "1000",
                            opacity: 0.7,
                            backgroundColor: "black",
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: "center"
                        }}
                    >
                        <PuffLoader color="white" size={150} loading={loading}/>
                    </div>
                )}
            </Segment>
        </Container>
    );
};

Layout.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withRouter(Layout);
