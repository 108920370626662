import {Button, Grid, Modal} from "semantic-ui-react";
import CustomExpansionPanel from "../../../CustomExpansionPanel/CustomExpansionPanel";
import ThroughLineHeader from "../../../ThroughLineHeader/throughLineHeader";
import React, {useState} from "react";
import {PropTypes} from "prop-types";
import {InformatiiSuplimentare} from "./InfoRendering";
import {divIcon} from 'leaflet'
import ReactDOMServer from "react-dom/server";
import CustomIcon from "../../../../utils/CustomIcon";
import {useSelector} from "react-redux";
import {Map, Marker, Popup, TileLayer} from "react-leaflet";
import mapStyles from "../../map.module.css";
import MarkerClusterGroup from "react-leaflet-markercluster/dist/react-leaflet-markercluster";
import LocationIcon from "@material-ui/icons/PersonPinCircle";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";

const InformatiiApv = (props) => {

    const {onClose, onOpen, open, apv} = props;

    const {mapLocation} = useSelector((state) => state.map);
    const [showRampe, setShowRampe] = useState(false)


    const styles = (theme) => ({
        myIcon: {
            background: `url(${LocationIcon})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            width: "25px",
            height: "25px",
        },
        redIcon: {
            color: "whiteblue",
        },
    });

    const makeIcon = (iconName, color) => {
        return divIcon({
            className: styles.redIcon,
            html: ReactDOMServer.renderToString(
                <CustomIcon
                    name={iconName}
                    style={{
                        width: "1.7rem",
                        height: "1.7em",
                    }}
                />
            ),
            iconSize: [25, 25],
        });
    };

    const center = (arr) => {
        let x = arr.map(latLng => latLng.lat)
        let y = arr.map(latLng => latLng.lng)
        let cx = (Math.min(...x) + Math.max(...x)) / 2
        let cy = (Math.min(...y) + Math.max(...y)) / 2
        return [cx, cy]
    }
    const renderRampePrimare = () => apv.rampePrimare.map(rampa => <Marker
        key={Math.random}
        position={rampa}
        icon={makeIcon(5)}
    >
        <Popup className={mapStyles.popup}>
            PLATFORMA PRIMARA
        </Popup>
    </Marker>)

    const toRampePrimare = () => {
        setShowRampe(!showRampe)
    }
    return <Modal
        onClose={onClose}
        onOpen={onOpen}
        open={open}
    >
        {/*<Modal.Header>Informatii APV</Modal.Header>*/}
        <Modal.Content>
            {!!apv && (
                <>
                    <CustomExpansionPanel
                        open
                        header={() => (
                            <ThroughLineHeader
                                style={{margin: 0, paddingBottom: "0.2em"}}
                                render={"h4"}
                            >
                                Informatii APV
                            </ThroughLineHeader>
                        )}
                        body={() => (
                            <>
                                <Grid>
                                    <Grid>
                                        <Grid.Row columns={3}>

                                            <Grid.Column>
                                                <p>
                                                    <strong>{"Num\u0103r APV:"}</strong>&nbsp;{apv.numar}
                                                </p>

                                                <p>
                                                    <strong>Ocol: </strong>&nbsp;{apv.ocol}
                                                </p>

                                                <p>
                                                    <strong>Denumire APV:</strong>&nbsp;{apv.denumire}
                                                </p>

                                                <p>
                                                    <strong>Stare APV:</strong>&nbsp;{apv.stare}
                                                </p>
                                            </Grid.Column>
                                            <Grid.Column>

                                                <p>
                                                    <strong>UP:</strong>&nbsp;{apv.up}
                                                </p>
                                                <p>
                                                    <strong>UA:</strong>&nbsp;{apv.ua}
                                                </p>
                                                <p>
                                                    <strong>{"Jude\u021B"}: </strong>&nbsp;{!!apv.judet ? apv.judet : "-"}
                                                </p>
                                                <p>
                                                    <strong>{`Volum ini\u021Bial`}: </strong>&nbsp;{!!apv.volumInitial ? apv.volumInitial : "-"}
                                                </p>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <p>
                                                    <strong>Natura Produs: </strong>&nbsp;{apv.naturaProdus}
                                                </p>
                                                <p>
                                                    <strong>Fel Taiere / Tratament: </strong>&nbsp;{apv.tratament}
                                                </p>
                                                <p>
                                                    <strong>Perioade de
                                                        exploatare: </strong>&nbsp;{!!apv.exploatari ? apv.exploatari.join(", ") : "-"}
                                                </p>

                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>

                                    {/*<p>*/}
                                    {/*    <strong>Prelungiri: </strong>&nbsp;{!!apv.prelungiri ? apv.prelungiri.join(", ") : "-"}*/}
                                    {/*</p>*/}
                                    {!!apv.volumGrupeSpecii &&
                                    <TableContainer container={Paper}>
                                        <Table aria-label="collapsible table" size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell align="left">{"Grup\u0103 Specie"}</TableCell>
                                                    <TableCell align="right">Volum</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {Object.keys(apv.volumGrupeSpecii).map((grupaSpecie, index) => (
                                                    <React.Fragment>
                                                        <TableRow>
                                                            <TableCell>

                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {grupaSpecie}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {Number(apv.volumGrupeSpecii[grupaSpecie]).toFixed(2)}
                                                            </TableCell>
                                                        </TableRow>
                                                    </React.Fragment>
                                                ))}
                                                <TableRow>
                                                    <TableCell/>
                                                    <TableCell align="left">Total</TableCell>
                                                    <TableCell align="right">
                                                        {Number(apv.volum).toFixed(2)}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    }
                                </Grid>
                                <Grid columns={1}>

                                    <Grid.Row stretched={true} columns={1}>

                                        <Grid.Column>
                                            <InformatiiSuplimentare apv={apv}/>
                                        </Grid.Column>
                                    </Grid.Row>

                                    {!!apv.rampePrimare && apv.rampePrimare.length > 0 &&
                                    <Grid.Row stretched={true} columns={1}>
                                        <Grid.Column>
                                            <Button onClick={toRampePrimare}>
                                                {!showRampe ? "Vezi" : "Ascunde"} Platforme
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>

                                    }

                                    {showRampe &&
                                    <Grid.Row stretched={true} columns={1}>
                                        <Grid.Column>
                                            <Map
                                                className={"markercluster-map"}
                                                center={center(apv.rampePrimare)}
                                                zoom={14}
                                                style={{
                                                    height: "400px",
                                                    width: "100%",
                                                    margin: "auto",
                                                    position: "relative"
                                                }}
                                            >
                                                <TileLayer
                                                    url={mapLocation.osm}
                                                />
                                                <MarkerClusterGroup>
                                                    {renderRampePrimare()}
                                                </MarkerClusterGroup>
                                            </Map></Grid.Column>
                                    </Grid.Row>}
                                </Grid>
                            </>
                        )}
                    />
                </>
            )}
        </Modal.Content>
    </Modal>
}

InformatiiApv.propTypes = {
    open: PropTypes.bool.isRequired,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    apv: PropTypes.object.isRequired
}
export default InformatiiApv;