import {createSlice} from "@reduxjs/toolkit";
import Axios from "axios";

const initialState = {
    companii: [],
    naturaProdus: [],
    felTaiere: []
};
const suggestionsSlice = createSlice({
    name: "suggestions",
    initialState,
    reducers: {
        setCompanii: (state, action) => {
            state.companii = action.payload.map((companie,index) => ({
                key: index,
                text: companie.label,
                value: companie.value
            }));
        },
        setNaturaProdus: (state, action) => {
            state.naturaProdus = action.payload.map((natura,index) => ({
                key: index,
                text: natura.label,
                value: natura.value
            }));
        },
        setFelTaiere: (state, action) => {
            state.felTaiere = action.payload.map((fel,index) => ({
                key: index,
                text: fel.label,
                value: fel.value
            }));
        },
        setStareApv: (state, action) => {
            state.stareApv = action.payload.map((stare,index) => ({
                key: index,
                text: stare.label,
                value: stare.value
            }));
        },
        resetSuggestions: (state) => initialState,
    },
});

export const { setCompanii,setFelTaiere,setNaturaProdus,setStareApv, resetRoutes } = suggestionsSlice.actions;
export default suggestionsSlice.reducer;

export const fetchCompanii = () => async (dispatch) => {
    Axios.get(`api/suggestions/ocol`)
        .then((res) => {
            dispatch(setCompanii(res.data));
        })
        .catch((err) => {
            // console.log(err);
        });
};
export const fetchNaturaProdusSuggestions = () => async (dispatch) => {
    Axios.get(`api/suggestions/natura-produs`)
        .then((res) => {
            dispatch(setNaturaProdus(res.data));
        })
        .catch((err) => {
            // console.log(err);
        });
};
export const fetchFelTaiereSuggestions = () => async (dispatch) => {
    Axios.get(`api/suggestions/fel-taiere`)
        .then((res) => {
            dispatch(setFelTaiere(res.data));
        })
        .catch((err) => {
            // console.log(err);
        });
};
export const fetchStareApvSuggestions = () => async (dispatch) => {
    Axios.get(`api/suggestions/stare-apv`)
        .then((res) => {
            dispatch(setStareApv(res.data));
        })
        .catch((err) => {
            // console.log(err);
        });
};


