import {combineReducers} from "@reduxjs/toolkit";
import avizeReducer from "./avizeSlice";
import apvReducer from "./apvSlice";
import layerReducer from './layerSlice';

const rootReducer = combineReducers({
  avize: avizeReducer,
  apvs: apvReducer,
  layer: layerReducer,
});

export default rootReducer;
