import {Grid, Tab} from "semantic-ui-react";
import CustomExpansionPanel from "../../../CustomExpansionPanel/CustomExpansionPanel";
import ThroughLineHeader from "../../../ThroughLineHeader/throughLineHeader";
import React from "react";

const informatiiEmitent = (aviz) => (
    <Grid.Column>
        <Grid.Row style={{paddingTop: "1rem"}}>
            <p>
                <strong>Denumire:</strong> <span>{aviz.emitent.denumire}</span>
            </p>
        </Grid.Row>
        <Grid.Row style={{paddingTop: "1rem"}}>
            <p style={{margin: "auto"}}>
                <strong>CUI: </strong>
                <span>{aviz.emitent.cui}</span>
            </p>
        </Grid.Row>
    </Grid.Column>
);
const informatiiTransportator = (aviz) => (
    <Grid.Column>
        <Grid.Row style={{paddingTop: "1rem"}}>
            <p>
                <strong>Tip Transportator: </strong>
                <span>
            {/*{aviz.transportator.nonSumal ? "Neutilizator" : "Utilizator"} SUMAL*/}
                    {aviz.transportator.tip}
          </span>
            </p>
        </Grid.Row>
        {!aviz.transportator.nonSumal && (
            <>
                <Grid.Row style={{paddingTop: "1rem"}}>
                    <p>
                        <strong>
                            Companie:
                        </strong>
                        <span>&nbsp;{aviz.transportator.denumire}</span>
                    </p>
                </Grid.Row>
                {/*<Grid.Row style={{paddingTop: "1rem"}}>*/}
                {/*    <p>*/}
                {/*        <strong>Identificator Transportator: </strong>*/}
                {/*        <span>{aviz.transportator.identificator}</span>*/}
                {/*    </p>*/}
                {/*</Grid.Row>*/}
                <Grid.Row style={{paddingTop: "1rem"}}>
                    <p>
                        <strong>CUI: </strong>
                        {!!aviz.transportator.cui ? aviz.transportator.cui : "Necompletat"}
                    </p>
                </Grid.Row>
            </>
        )}
    </Grid.Column>
);
const informatiiBeneficiar = (aviz) => (
    <Grid.Column>
        <Grid.Row style={{paddingTop: "1rem"}}>
            <p>
                <strong>Tip Persoana: </strong>
                <span>{aviz.beneficiar.tip}</span>
            </p>
        </Grid.Row>
        {/*<Grid.Row style={{paddingTop: "1rem"}}>*/}
        {/*    <p>*/}
        {/*        <strong>Adresa: </strong>*/}
        {/*        <span>{aviz.beneficiar.adresa}</span>*/}
        {/*    </p>*/}
        {/*</Grid.Row>*/}
        {/*<Grid.Row style={{paddingTop: "1rem"}}>*/}
        {/*    <p>*/}
        {/*        <strong>Tara: </strong>*/}
        {/*        <span>{aviz.beneficiar.tara}</span>*/}
        {/*    </p>*/}
        {/*</Grid.Row>*/}

        {/*<Grid.Row style={{paddingTop: "1rem"}}>*/}
        {/*    <p>*/}
        {/*        <strong>Depozit: </strong>*/}
        {/*        <span>{aviz.beneficiar.depozit}</span>*/}
        {/*    </p>*/}
        {/*</Grid.Row>*/}
    </Grid.Column>
);
const informatiiDestinatar = (aviz) => (
    <Grid.Column>
        <Grid.Row style={{paddingTop: "1rem"}}>
            <p>
                <strong>Tip Persoana: </strong>
                <span>{aviz.destinatar.tip}</span>
            </p>
        </Grid.Row>
        {/*<Grid.Row style={{paddingTop: "1rem"}}>*/}
        {/*    <p>*/}
        {/*        <strong>Adresa: </strong>*/}
        {/*        <span>{aviz.destinatar.adresa}</span>*/}
        {/*    </p>*/}
        {/*</Grid.Row>*/}
        {/*<Grid.Row style={{paddingTop: "1rem"}}>*/}
        {/*    <p>*/}
        {/*        <strong>Tara: </strong>*/}
        {/*        <span>{aviz.destinatar.tara}</span>*/}
        {/*    </p>*/}
        {/*</Grid.Row>*/}

        {/*<Grid.Row style={{paddingTop: "1rem"}}>*/}
        {/*    <p>*/}
        {/*        <strong>Depozit: </strong>*/}
        {/*        <span>{aviz.destinatar.depozit}</span>*/}
        {/*    </p>*/}
        {/*</Grid.Row>*/}
    </Grid.Column>
);
const informatiiEntitatiImplicatePanes = (aviz) => {

    let panes = [];


    let emitentPane = {
        menuItem: "Emitent",
        render: () => !!aviz.emitent ?
            <Tab.Pane>{informatiiEmitent(aviz)}</Tab.Pane> : "Nu exist\u0103 informa\u021Bii",
    };
    let transportatorPane = {
        menuItem: "Transportator",
        render: () => aviz.transportator ?
            <Tab.Pane>{informatiiTransportator(aviz)}</Tab.Pane> : "Nu exist\u0103 informa\u021Bii",
    };
    let beneficiarPane = {
        menuItem: "Destinatar",
        render: () => aviz.beneficiar ?
            <Tab.Pane>{informatiiBeneficiar(aviz)}</Tab.Pane> : "Nu exist\u0103 informa\u021Bii",
    };
    let destinatarPane = {
        menuItem: "Punct de Desc\u0103rcare",
        render: () => aviz.destinatar ?
            <Tab.Pane>{informatiiDestinatar(aviz)}</Tab.Pane> : "Nu exist\u0103 informa\u021Bii",
    };
    !!aviz.emitent && panes.push(emitentPane);
    !!aviz.transportator && panes.push(transportatorPane);
    !!aviz.beneficar && panes.push(beneficiarPane);
    !!aviz.destinatar && panes.push(destinatarPane);

    return panes;
}

export const informatiiEntitatiImplicate = (aviz) => (
    <CustomExpansionPanel
        header={() => (
            <ThroughLineHeader
                style={{margin: 0, paddingBottom: "0.2em"}}
                render={"h4"}
            >
                {"Informa\u021Bii Entit\u0103\u021Bi Implicate"}
            </ThroughLineHeader>
        )}
        body={() => (
            <div className={"tabgrid"}>
                <Tab
                    menu={{fluid: true, vertical: true, tabular: true}}
                    // grid={{className:"stackable"}}
                    panes={informatiiEntitatiImplicatePanes(aviz)}
                />
            </div>
        )}
    />
);