import {layers} from "../../reducers/layers/layerSlice";

export const ApvStatus = {
    APROBAT: {value: 3, layer: layers.APV, mapLayer: {value: 4}},
    AUTORIZAT: {value: 4, layer: layers.APV, mapLayer: {value: 4}},
    PREDAT: {value: 5, layer: layers.APV, mapLayer: {value: 4}},
    REPRIMIT: {value: 6, layer: layers.APV, mapLayer: {value: 4}},
    PREGATIT_PENTRU_PREDARE: {value: 8, layer: layers.APV, mapLayer: {value: 4}},
    PREGATIT_PENTRU_REPRIMIRE: {value: 9, layer: layers.APV, mapLayer: {value: 4}},
    RETRAS: {value: 10, layer: layers.APV, mapLayer: {value: 4}},
    ISTORIC: {value: 0, layer: layers.APV, mapLayer: {value: 4}},
};

export const typeOfAviz = (value) => {
    switch (value) {
        case 1:
            return ApvStatus[0];
        case 2:
            return ApvStatus.DEPOZIT;
        default:
            return ApvStatus.DEPOZIT;
    }
};
