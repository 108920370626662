import React, {useEffect, useState} from 'react'
import CustomExpansionPanel from "../../CustomExpansionPanel/CustomExpansionPanel";
import ThroughLineHeader from "../../ThroughLineHeader/throughLineHeader";
import {Button as SemanticButton, Grid, Icon, Input, Label} from "semantic-ui-react";
import {fetchAvize} from "../../../reducers/layers/avizeSlice";
import Control from "react-leaflet-control";
import {useDispatch, useSelector} from "react-redux";
import {layers, resetFilters, setFilters} from "../../../reducers/layers/layerSlice";
import moment from 'moment'
import styles from '../map.module.css'
import {validateLength} from "../../../utils/shared";


const AvizeFilters = (props) => {
    const regExp = new RegExp("^[a-zA-Z0-9]*$");
    const {filters} = useSelector(state => state.layers.layer[layers.AVIZE.name])
    const [errors, setErrors] = useState({})
    const changeFilter = (value) => {
        dispatch(setFilters(layers.AVIZE, value));
    }

    const {avize} = useSelector((state) => state.layers.avize);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAvize());
        return () => {
            dispatch(resetFilters());
        }
    }, [])


    function getSelectedDate() {
        return moment(filters.date).minute(0);
    }

    return <Control position={"bottomright"}>
        <CustomExpansionPanel
            header={() => <ThroughLineHeader
                style={{margin: 0}}
                render={"h4"}
            >
                {"C\u0103utare"}
            </ThroughLineHeader>}
            body={() =>
                <Grid className={styles.filterCard}>
                    <Grid.Row>
                        <Grid.Column
                            style={{textAlign: "center", fontSize: '14px', verticalAlign: "center"}}>
                            <Grid>
                                <Grid.Row columns={2} divided={"ho"}>
                                    <Grid.Column>
                                        <Label fluid
                                               color={"teal"}
                                               className={"custom-label fluid"}>
                                            {moment().add(-4, 'd').format("DD/MM/yyyy")}
                                        </Label>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Label fluid
                                               color={"teal"}
                                               className={"custom-label fluid"}>{moment().format("DD/MM/yyyy")}</Label>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column style={{textAlign: "center"}}>
                            <Label color={"red"} className={"custom-label fluid"}>
                                Avize
                                <Label.Detail>
                                    {!!avize ? Object.keys(avize).reduce((acc, key) => acc + avize[key].length, 0) : '-'}
                                </Label.Detail>
                            </Label>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <div>
                                <Input
                                    // label={{ tag: true, content: "Nr. auto" }}
                                    labelPosition="right"
                                    name={"nr"}
                                    placeholder={"Nr. Auto"}
                                    iconPosition="right"
                                    icon="truck"
                                    maxLength={"20"}
                                    value={filters.nr}
                                    error={validateLength(filters.nr,3,20)}
                                    fluid
                                    onChange={(event, {value}) => {
                                        if(!!errors.nr)
                                            setErrors({...errors, nr: false})
                                        if(!regExp.test(value.trim()))
                                            return;
                                        changeFilter({nr: value.trim(), tip: "", cod: "", nrApv: ""});
                                    }}
                                />
                                {!!errors.nr && <label style={{position: "absolute", color:"red", opacity:'0.6'}}>{"Min. 3 caractere"}</label>}
                            </div>

                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <div>
                            <Input
                                // label={{ tag: true, content: "Nr. auto" }}
                                labelPosition="right"
                                name={"cod"}
                                placeholder="Cod Aviz"
                                iconPosition="right"
                                icon="barcode"
                                maxLength={"29"}
                                fluid
                                error={validateLength(filters.cod,5,29)}
                                value={filters.cod}
                                onChange={(event, {value}) => {
                                    if(!!errors.cod)
                                        setErrors({...errors, cod: false})
                                    if(!regExp.test(value.trim()))
                                        return;
                                    changeFilter({nr: "", tip: "", cod: value.trim(), nrApv: ""});
                                }}
                            />
                            {!!errors.cod && <label style={{position: "absolute", color:"red", opacity:'0.6'}}>{"Min. 5 caractere"}</label>}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <div>

                            <Input
                                // label={{ tag: true, content: "Nr. auto" }}
                                labelPosition="right"
                                name={"nrApv"}
                                placeholder="Nr. APV"
                                iconPosition="right"
                                icon="file"
                                maxLength={"15"}
                                fluid
                                error={validateLength(filters.nrApv,5,15)}
                                value={filters.nrApv}
                                onChange={(event, {value}) => {
                                    if(!!errors.nrApv)
                                        setErrors({...errors, nrApv: false})
                                    if(!regExp.test(value.trim()))
                                        return;
                                    changeFilter({tip: "", nr: "", cod: "", nrApv: value.trim()});
                                }}
                            />
                            {!!errors.nrApv && <label style={{position: "absolute", color:"red", opacity:'0.6'}}>{"Min. 5 caractere"}</label>}
                            </div>
                        </Grid.Column>
                    </Grid.Row>

                    {/*<Grid.Row>*/}
                    {/*    <TimeRange*/}
                    {/*        ticksNumber={36}*/}
                    {/*        selectedInterval={[new Date(filters.start), new Date(filters.end)]}*/}
                    {/*        timelineInterval={getTimelineInterval()}*/}
                    {/*        onUpdateCallback={() => {*/}
                    {/*        }}*/}
                    {/*        onChangeCallback={(data) => {*/}
                    {/*            if (data[0].toString() === "Invalid Date" || data[1].toString() === "Invalid Date")*/}
                    {/*                return;*/}
                    {/*            let newFilters = {*/}
                    {/*                ...filters,*/}
                    {/*                start: new Date(data[0]).getTime(),*/}
                    {/*                end: new Date(data[1]).getTime()*/}
                    {/*            };*/}
                    {/*            // dispatch(fetchAvize(newFilters))*/}
                    {/*            changeFilter(newFilters)*/}
                    {/*        }}*/}
                    {/*        formatTick={(ms) => moment(ms).format("HH")}*/}
                    {/*    />*/}
                    {/*</Grid.Row>*/}
                    <Grid.Row>
                        <SemanticButton
                            fluid
                            icon
                            color={"teal"}
                            onClick={() => {
                                if (validateLength(filters.nr,3,20)) {
                                    setErrors({...errors, nr: true})
                                    return
                                }
                                if (validateLength(filters.cod,5,29)) {
                                    setErrors({...errors, cod: true})
                                    return
                                }
                                if(validateLength(filters.nrApv,5,15)){
                                    setErrors({...errors, nrApv: true})
                                    return
                                }
                                dispatch(fetchAvize(filters));
                            }}
                        >
                            <Icon name={"search"}/>
                            {"C\u0103utare"}
                        </SemanticButton>
                    </Grid.Row>
                </Grid>
            }
            open={false}
        />
    </Control>
}

export default AvizeFilters;