import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {configureStore} from '@reduxjs/toolkit';
import rootReducer from './reducers/index'
import {Provider} from 'react-redux'
import ReduxToaster from 'react-redux-toastr'

import firebase from "firebase/app"
import "firebase/analytics";

const store = configureStore({reducer: rootReducer});


// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
const firebaseConfig = {
    apiKey: "AIzaSyARWZJI93Gd-nrr5uWn-SQGchWxm0pZgzU",
    authDomain: "sumal-inspector.firebaseapp.com",
    projectId: "sumal-inspector",
    storageBucket: "sumal-inspector.appspot.com",
    messagingSenderId: "338059552590",
    appId: "1:338059552590:web:f05e7783ca841b9f63201f",
    measurementId: "G-ZBSGPJNP20"
};
firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();

ReactDOM.render(
    <Provider store={store}>
        <div id="app-wrapper">
            <ReduxToaster
                timeOut={5000}
                newestOnTop={false}
                preventDuplicates={false}
                position="bottom-right"
                transitionIn="bounceIn"
                transitionOut="fadeOut"
                progressBar
            />
            <App/>
        </div>
    </Provider>
    , document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
