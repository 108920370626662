import {layers} from "../../reducers/layers/layerSlice";

export const AvizType = {
  DEPOZIT: {value:2, layer:layers.AVIZE, mapLayer: {value:1}},
  LOCUL_RECOLTARII: {value: 1, layer:layers.AVIZE,mapLayer: {value:1}},
  EXTRACOMUNITARE: {value: 7, layer:layers.AVIZE,mapLayer: {value:1}},
  ALTELE: {value: 99, layer: layers.AVIZE,mapLayer: {value:1}}
};

export const typeOfAviz = (value) => {
  switch (value) {
    case 1:
      return AvizType.LOCUL_RECOLTARII;
    case 2:
      return AvizType.DEPOZIT;
    case 7:
      return AvizType.EXTRACOMUNITARE;
    case 99:
      return AvizType.ALTELE;
    default:
      return AvizType.ALTELE;
  }
};
