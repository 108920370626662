import React, {Component} from "react";
import ConnectedLayout from "./components/Layout/layout";
import {HashRouter, Route} from "react-router-dom";
import "./app_style/fonts.css";
import "./app_style/app.css";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";


const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#00887A",
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#00887A",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#00887A",
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "#00887A",
        height: "20px !important",
      },
      deleteIcon: {
        color: "inherit",
      },
    },
    MuiToolbar: {
      root: {
        paddingLeft: "0 !important",
        paddingRight: "24px !important",
      },
      gutters: {
        paddingLeft: "24px !important",
        paddingRight: "24px !important",
      },
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottom: "2px solid #00887A",
        },
      },
    },
    MuiInputBase: {
      input: {
        height: "2em",
      },
    },
    MuiDialog: {
      paperWidthMd: {
        maxWidth: window.screen.availWidth > 720 ? "75%" : "90%",
        minWidth: window.screen.availWidth > 720 ? "75%" : "90%",
      },
    },
    MuiTableCell: {
      head: {
        color: "rgb(0, 136, 122)",
        fontWeight: "bold",
      },
    },
    MuiFormLabel: {
      root: {
        paddingBottom: "1em",
        "&$focused": {
          color: "#00887A !important",
        },
      },
    },
    MuiDrawer: {
      paper: {
        color: "#00887A !important",
      },
    },
    MuiDialogTitle: {
      root: {
        background: "#00887A",
        color: "#FFFFFF",
        textAlign: "center",
      },
    },
  },
});

export default class App extends Component {
  render() {
    const content = (
        <MuiThemeProvider theme={materialTheme}>
          <HashRouter>
            <Route path="/" component={ConnectedLayout} />
          </HashRouter>
        </MuiThemeProvider>
    );
    let appContent;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      appContent = <React.StrictMode>{content}</React.StrictMode>;
    } else {
      appContent = content;
    }
    return appContent;
  }
}
