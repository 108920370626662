import {Grid, Tab} from "semantic-ui-react";
import CustomExpansionPanel from "../../../CustomExpansionPanel/CustomExpansionPanel";
import ThroughLineHeader from "../../../ThroughLineHeader/throughLineHeader";
import React from "react";

const informatiiAutorizatie = (apv) => {
    const {autorizatie} = apv;
    return (<Grid.Column>
        <Grid.Row style={{paddingTop: "1rem"}}>
            <p>
                <strong>Titular:</strong> <span>{!!autorizatie.titular ? autorizatie.titular : "-"}</span>
            </p>
        </Grid.Row>
        <Grid.Row style={{paddingTop: "1rem"}}>
            <p>
                <strong>Numar:</strong> <span>{!!autorizatie.numar ? autorizatie.numar : "-"}</span>
            </p>
        </Grid.Row>
        {/*<Grid.Row style={{paddingTop: "1rem"}}>*/}
        {/*    <p>*/}
        {/*        <strong>Stare:</strong> <span>{!!autorizatie.stare ? autorizatie.stare : "-"}</span>*/}
        {/*    </p>*/}
        {/*</Grid.Row>*/}
        {!!apv.emitent && <Grid.Row style={{paddingTop: "1rem"}}>
            <p>
                <strong>Emitent:</strong> <span>{!!apv.emitent.denumire ? apv.emitent.denumire : "-"}</span>
            </p>
        </Grid.Row>}
    </Grid.Column>)
};

const informatiiSuplimentarePanes = (apv) => {

    let panes = [];
    let autorizatiePane = {
        menuItem: "Autoriza\u021bie de exploatare",
        render: () => !!apv.autorizatie ?
            <Tab.Pane>{informatiiAutorizatie(apv)}</Tab.Pane> : "Nu exist\u0103 informa\u021Bii",
    };

    panes.push(autorizatiePane)
    return panes;
}

export const InformatiiSuplimentare = ({apv}) => (
    <CustomExpansionPanel
        header={() => (
            <ThroughLineHeader
                style={{margin: 0, paddingBottom: "0.2em"}}
                render={"h4"}
            >
                {"Informa\u021bii Suplimentare"}
            </ThroughLineHeader>
        )}
        body={() => (
            <Tab
                menu={{fluid: true, vertical: true, tabular: true}}
                panes={informatiiSuplimentarePanes(apv)}
            />
        )}
    />
);