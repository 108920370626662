import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ReactDOMServer from "react-dom/server";
import Control from "react-leaflet-control";
import CustomIcon from "../../utils/CustomIcon";
import CustomExpansionPanel from "../CustomExpansionPanel/CustomExpansionPanel";
import ThroughLineHeader from "../ThroughLineHeader/throughLineHeader";
import {Button, Checkbox, Grid, Icon} from "semantic-ui-react";
import {GeoJSON, Map, Polyline, TileLayer} from "react-leaflet";
import {fetchLayerInfo, onFirstView, resetLayerInfo} from "../../reducers/mapSlice";

import axios from "axios";
import {toggleLoading} from "../../reducers/layoutSlice";

import AvizeFilters from "./filters/AvizeFilters";
import {triggerToasterSuccess} from "../../utils/shared";
import {ApvStatus} from "../Enum/ApvStatus";
import {AvizType} from "../Enum/AvizType";
import {resetAvize} from "../../reducers/layers/avizeSlice";
import {resetApvs} from "../../reducers/layers/apvSlice";
import {ApvCodFilters} from "./filters/ApvCodFilters";
import {ApvFilters} from "./filters/ApvFilters";
import {IconButton, Paper} from "@material-ui/core";

import "semantic-ui-css/semantic.min.css";
import "./map.css";
import "leaflet/dist/leaflet.css";
import {resetFilters, setFilters} from "../../reducers/layers/layerSlice";
import ApvMarkers from "./markers/ApvMarkers";
import AvizMarkers from "./markers/AvizMarkers";

require("react-leaflet-markercluster/dist/styles.min.css");


var moment = require("moment");

moment().format();
moment.locale("ro");


const InspectorMap = (props) => {

    const dispatch = useDispatch();
    const mapRef = useRef();

    const [geoLayerUrl, setGeoLayerUrl] = useState(null);
    const [geoLayer, setGeoLayer] = useState(null);
    const [minZoom, setMinZoom] = useState(18);

    const [position, setPosition] = useState([45.843615, 24.969258]);
    const [viewport, setViewport] = useState({
        center: position,
        zoom: 7.5,
    });

    const [legendPosition, setLegendPosition] = useState("topright");
    const [layerLocation, setLayerLocation] = useState("");


    const {mapLocation, layerTypes} = useSelector((state) => state.map);
    const {visibleRoute} = useSelector((state) => state.routes);

    const [checkedLayer, setCheckedLayer] = useState(1);

    useEffect(() => {
        dispatch(onFirstView());
    }, []);

    const setLegendFilter = (value) => {
        dispatch(setFilters(value.layer, {tip: value.value}))
        dispatch(fetchLayerInfo(value.mapLayer));
    };

    function changeLayer(type) {
        dispatch(resetFilters())
        dispatch(resetApvs())
        // setApvMarkers(null)
        dispatch(resetAvize())

        setGeoLayer(null);
        setGeoLayerUrl(null);

        if (!!type.url) {
            dispatch(triggerToasterSuccess(`Pentru a vizualiza layer-ul, face\u021bi zoom in pe zona de interes`));
            setGeoLayerUrl(type.url);
        }
        if (!!type.value) {
            // dispatch(fetchLayerInfo(type));

            setCheckedLayer(type.value);
            return;
        }

        setCheckedLayer(type)
    }

    const renderLegendHeader = () => {
        return (
            <ThroughLineHeader
                style={{margin: 0}}
                render={"h4"}
            >
                Legenda
            </ThroughLineHeader>
        );
    };
    const renderLegendBodyContent = (value) => {
        return <>
            {Object.keys(value).map((type) => (
                <Grid.Row
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <IconButton
                        size={"small"}
                        onClick={() => setLegendFilter(value[type])}
                    >
                        <CustomIcon
                            name={value[type].value}
                            style={{
                                width: "1.5em",
                                height: "1.5em",
                            }}
                        />
                    </IconButton>
                    <span>
                  &nbsp;&nbsp;&mdash;&nbsp;&nbsp;&nbsp;
                        {type.split("_").join(" ")}
                </span>
                </Grid.Row>
            ))}
        </>;
    }
    const renderLegendBody = () => {
        return (
            <div style={{backgroundColor: "white", width: "12rem", height: "auto"}}>
                {
                    checkedLayer === 1 ||
                    checkedLayer === 2 ||
                    checkedLayer === 3 ? renderLegendBodyContent(AvizType) : (
                        renderLegendBodyContent(ApvStatus)
                    )}
                <ThroughLineHeader
                    style={{margin: 0, paddingBottom: "1em"}}
                    render={"h4"}
                >
                    Layer
                </ThroughLineHeader>
                {layerTypes.map((type) => (<Grid.Row>
                        <Checkbox
                            label={type.name}
                            checked={checkedLayer === type.value}
                            onChange={(event, {checked}) => {
                                // setFilters(avizeFilters);
                                if (checked) {
                                    changeLayer(type);
                                } else {
                                    dispatch(resetLayerInfo(type));
                                    changeLayer(0);
                                }
                            }}
                        />
                    </Grid.Row>
                ))}
            </div>
        );
    };
    const renderLegend = () => {
        return [
            <Control position={legendPosition}>
                <Paper>
                    <CustomExpansionPanel
                        header={renderLegendHeader}
                        body={renderLegendBody}
                        open={true}
                    />
                    {/* {this.renderLegendBody()} */}
                </Paper>
            </Control>,
            <Control position={"bottomleft"}>
                <Paper style={{background: 'transparent!important'}}>
                    <Button icon onClick={() => setLayerLocation(mapLocation.osm)}><Icon
                        name={'map outline'}/> OSM</Button>
                    <Button onClick={() => setLayerLocation(mapLocation.gmaps)}>
                        <Icon
                            name={'map outline'}/>
                        GMAPS
                    </Button>
                </Paper>
            </Control>,
            checkedLayer === 1 && [
                <AvizeFilters/>
            ],
            // checkedLayer === 2 && <AvizNrFilters/>,
            // checkedLayer === 3 && <AvizCodFilters fetchCallback={decodeAviz}/>,
            checkedLayer === 4 && <Control id="apv-filters" position={"bottomright"}> <ApvFilters/> </Control>,
            checkedLayer === 5 && <ApvCodFilters/>
        ];
    };

    const LayerPopup = ({feature}) => {
        return (
            <div style={{maxHeight: '300px', overflow: 'scroll'}}>
                {/*<p>{`Nume: ${feature.properties["ap"]}`}</p>*/}
                {/*<p>{`Cod: ${feature.properties["code_nat"]}`}</p>*/}
                {/*<p>{`Suprafata: ${feature.properties["suprafata"]}`}</p>*/}
                {Object.keys(feature.properties).map(key => <p><b>{key}</b>: {feature.properties[key]}</p>)}
            </div>
        )
    }

    const bindLayerPopup = (feature, layer) => {
        let content = ReactDOMServer.renderToString(<LayerPopup feature={feature}/>);
        layer.bindPopup(content)
    }

    const getFillColor = () => {
        switch (checkedLayer) {
            case 10:
                return "#4daf4a"; //harti amenajistice
            case 11:
                return "#e41a1c"; //paduri virgine
            case 12:
                return "#377eb8"; //arii protejate
            case 13:
                return "#4daf4a" //harti amenajistice privat
            case 14:
                return "#4daf4a" //harti amenajistice stat
        }
    }

    const layerStyle = (feature) => {
        const color = getFillColor();
        return {
            fillColor: color,
            color: color,
            opacity: 1,
            weight: 3
        }
    }

    return [
        !!mapLocation && (
            <div style={{display: "flex", flexDirection: "column", position: "relative"}}>
                <Map
                    tap={false}
                    preferCanvas={true}
                    className={"markercluster-map inspmap"}
                    viewport={viewport}
                    ref={mapRef}
                    onMoveEnd={() => {
                        const {_northEast, _southWest} = mapRef.current.leafletElement.getBounds();
                        if (!!geoLayerUrl && mapRef.current.leafletElement.getZoom() > 11) {
                            dispatch(toggleLoading())
                            axios.get(`${geoLayerUrl}?bbox=${_southWest.lat},${_southWest.lng},${_northEast.lat},${_northEast.lng},urn:ogc:def:crs:EPSG:4326`).then((res) => {
                                if (!!res.data.totalFeatures && res.data.totalFeatures > 0)
                                    setGeoLayer(<GeoJSON
                                        style={layerStyle}
                                        key={Math.random()}
                                        data={res.data}
                                        onEachFeature={bindLayerPopup}/>);
                            }).finally(() => {
                                dispatch(toggleLoading())
                            })
                        }
                    }}
                >
                    <TileLayer
                        url={layerLocation ? layerLocation : mapLocation.osm}
                    />
                    {renderLegend()}
                    <Polyline
                        key={Math.random()}
                        positions={[...visibleRoute]}
                        color={"red"}
                    />
                    <AvizMarkers mapRef={mapRef}/>
                    <ApvMarkers mapRef={mapRef}/>
                    {geoLayer}
                </Map>


            </div>
        )
    ];
};

export default (InspectorMap);
