import React from 'react';

import styling from './throughLineHeader.module.css';

const ThroughLineHeader = (props) =>{
    const {render, style} = props;
    return (
        <div>
            <props.render className={styling.decorated} style={{...style}}><span>{props.children}</span></props.render>
        </div>
    )
}
export default ThroughLineHeader;
