import React, {Component} from "react";
import {AvizType} from "../components/Enum/AvizType";
import {Icon} from '@iconify/react'
import circleIcon from '@iconify/icons-map/circle'
import {ApvStatus} from "../components/Enum/ApvStatus";

export class CustomIcon extends Component {
    render() {
        const {name, style} = this.props;
        const iconMap = new Map([
            [AvizType.LOCUL_RECOLTARII.value,
                <Icon icon={circleIcon} color={'#D2060C'} {...this.props} />],
            [AvizType.DEPOZIT.value,
                <Icon icon={circleIcon} color={'#FCA547'} {...this.props}
                />],
            [ApvStatus.APROBAT.value, <Icon icon={circleIcon} color={'#22731B'} {...this.props}/>],
            [ApvStatus.AUTORIZAT.value, <Icon icon={circleIcon} color={'#EDE424'} {...this.props}/>],
            [ApvStatus.PREDAT.value, <Icon icon={circleIcon} color={'#185BF5'} {...this.props}/>],
            [ApvStatus.REPRIMIT.value, <Icon icon={circleIcon} color={'#5F4101'} {...this.props}/>],
            [ApvStatus.ISTORIC.value, <Icon icon={circleIcon} color={'#75046B'} {...this.props}/>],
            [ApvStatus.RETRAS.value, <Icon icon={circleIcon} color={'#DE0108'} {...this.props}/>],
            [ApvStatus.PREGATIT_PENTRU_PREDARE.value, <Icon icon={circleIcon} color={'#185BF5'} {...this.props}/>],
            [ApvStatus.PREGATIT_PENTRU_REPRIMIRE.value, <Icon icon={circleIcon} color={'#5F4101'} {...this.props}/>],
            [AvizType.ALTELE.value,
                <Icon icon={circleIcon} color={'#0a5b30'} {...this.props}
                />],
            [AvizType.EXTRACOMUNITARE.value,
                <Icon icon={circleIcon} color={'#27717e'} {...this.props}
                />],
        ]);
        return iconMap.get(name) ? iconMap.get(name) : null;
    }
}

export default CustomIcon;
