import { createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { triggerToasterSuccess, triggerToasterError } from "../utils/shared";

const initialState = {
  visibleRoute: [],
};
const routeSlice = createSlice({
  name: "avize",
  initialState,
  reducers: {
    setVisibleRoute: (state, action) => {
      state.visibleRoute = action.payload;
    },
    resetRoutes: (state) => initialState,
  },
});

export const { setVisibleRoute, resetRoutes } = routeSlice.actions;
export default routeSlice.reducer;

export const fetchRoute = (aviz) => async (dispatch) => {
  Axios.get(`api/aviz/${aviz.codAviz}/route`)
    .then((res) => {
      dispatch(setVisibleRoute(res.data));
    })
    .catch((err) => {
      console.log(err);
      triggerToasterError("A aparut o eroare la aducerea traseului efectuat.");
    });
};
