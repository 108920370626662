import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  menuIsOpen: true,
  userProfileIsOpen: false,
  loading: false,
  accountInfo: {
    numeCompanie: "",
  },
};

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    toggleMenu(state, action) {
      state.menuIsOpen = !state.menuIsOpen;
    },
    toggleUserProfile(state, action) {
      state.userProfileIsOpen = !state.userProfileIsOpen;
    },
    toggleLoading: (state) => {
      state.loading = !state.loading;
    },
    logout: (state) => initialState,
    setAccountInfo: (state, action) =>
      (state.accountInfo = action.payload.accountInfo),
  },
});

export const {
  toggleMenu,
  toggleUserProfile,
  logout,
  setAccountInfo,
  toggleLoading,
} = layoutSlice.actions;
export default layoutSlice.reducer;

export const fetchAccountInfo = () => async (dispatch) => {
  axios.get("/auth/user").then((res) => dispatch(setAccountInfo(res.data)));
};
