import {combineReducers} from "@reduxjs/toolkit";
import {loadingBarReducer} from "react-redux-loading-bar";
import {reducer as toasterReducer} from "react-redux-toastr";
import layout from "./layoutSlice";
import appHasError from "./appHasErrorSlice";
import suggestionsReducer from './suggestionsSlice'
// import messages from "./messages";
import mapReducer from "./mapSlice";
import layersReducer from "./layers/index";
import routesReducer from "./routeSlice";

const rootReducer = combineReducers({
  loadingBar: loadingBarReducer,
  toastr: toasterReducer,
  layout,
  appHasError,
  map: mapReducer,
  layers: layersReducer,
  suggestions: suggestionsReducer,
  routes: routesReducer,
});

export default rootReducer;
